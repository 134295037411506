<template>
  <v-container>
    <!-- pageHeader -->
    <v-row>
      <v-col cols="12" md="8">
        <h1 class="headline">Inventory Sitemap Properties</h1>
        <p class="body-2 mt-1">
          <span class="d-block"
            >This page lists all the properties scraped for inventory</span
          >
          <span class="d-block"
            >Click add new property to include that property in the next
            scrape</span
          >
          <span class="d-block"
            >Adding or deleting a property here will
            <strong>NOT</strong> automatically start a new scrape or update live
            inventory</span
          >
        </p>
      </v-col>
      <v-col cols="12" md="4" class="text-right">
        <v-btn
          color="primary"
          depressed
          @click="addPropertyDialog = true"
          :loading="loading"
          :disabled="loading"
        >
          <v-icon left>mdi-plus</v-icon>
          Add Inventory Property</v-btn
        >
      </v-col>
    </v-row>
    <!-- pageHeader -->

    <!-- propertyIDs table -->
    <v-row class="mb-12">
      <v-col cols="12">
        <v-card outlined>
          <v-card-text>
            <v-row class="mb-2">
              <v-col cols="12" md="8">
                <h3 class="title text--primary font-weight-regular d-block">
                  Resort Properties
                </h3>
                <p class="mb-0">
                  {{ numberOfTBProperties }} TB / {{ numberOfDWProperties }} DW
                </p>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="searchProperties"
                  append-icon="mdi-magnify"
                  label="Search Properties"
                  single-line
                  hide-details
                  clearable
                  dense
                  :loading="loading"
                  :disabled="loading"
                ></v-text-field
              ></v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :loading="loading"
            :headers="[
              { text: 'Resort Property ID', value: 'resortPropertyID' },
              { text: 'Supplier', value: 'source' },
              { text: 'Delete', value: 'delete' },
            ]"
            :items="propertyIDs"
            :items-per-page="-1"
            :search="searchProperties"
            dense
            sort-by="resortPropertyID"
          >
            <template v-slot:[`item.delete`]="{ item }">
              <v-btn
                small
                icon
                color="error"
                @click="
                  deletePropertyDialog = true;
                  idToDelete = item;
                "
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <!-- propertyIDs table -->
    <!-- delete property dialog -->
    <v-dialog v-model="deletePropertyDialog" width="600">
      <v-card :loading="loading">
        <v-card-text class="body-1 text--primary pt-2">
          <h4 class="title mt-4">Delete This Property?</h4>
          <p class="mt-4">Deleting a property will:</p>
          <ul>
            <li>Remove it from the list of future scrapes</li>
            <li>
              Archive the property immediately so that it does not display in
              live traveler inventory
            </li>
          </ul>
          <p class="mt-4">
            Deleting this property will <strong>NOT</strong> automatically
            perform a new scrape
          </p>
          <p class="body-2 italic">This action cannot be undone</p>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            text
            @click="
              deletePropertyDialog = false;
              idToDelete = '';
            "
            :loading="loading"
            :disabled="loading"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            @click="updatePropertiesSitemapDelete"
            :loading="loading"
            :disabled="loading"
          >
            Delete Property
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- delete property dialog -->
    <!-- add property dialog -->
    <v-dialog v-model="addPropertyDialog" width="600">
      <v-card :loading="loading">
        <v-card-text class="body-1 text--primary pt-2">
          <h4 class="title mt-4">Add New Property to Scrape</h4>
          <p class="mt-4">
            Adding a new property here will add it to the list to be scraped. It
            will <strong>NOT</strong> automatically perform a scrape or update
            live inventory.
          </p>

          <v-fade-transition>
            <ol v-if="updateSource == 'dreamweeks'">
              <li>
                <a
                  href="https://www.dreamvacationweek.com/web/my/auth/loginPage"
                  target="_blank"
                >
                  Login</a
                >
                in to dreamweeks using your certificate
              </li>
              <li>Search the destination area you need (ex: Orlando)</li>
              <li>Click on the resort you need to add (ex: Westgate Lakes)</li>
              <li>
                Copy and paste the 3 digit resort found under the name into the
                text field below (ex:
                <span class="red--text font-weight-medium">RYS</span>)
              </li>
            </ol>
          </v-fade-transition>

          <v-fade-transition>
            <ol v-if="updateSource == 'tripbeat'">
              <li>
                Visit the tripbeat website
                <a href="https://www.tripbeat.com/" target="_blank"> here</a>
              </li>
              <li>Select destinations (ex: United States)</li>
              <li>Select state/territory (ex: Florida)</li>
              <li>Select region(s) (ex: Daytona Beach Area)</li>
              <li>Click the resort you'd like to add</li>
              <li>
                Copy and paste the 4 digit property ID found under the name into
                the text field below (ex:
                <span class="red--text font-weight-medium">2855</span>)
              </li>
            </ol>
          </v-fade-transition>

          <v-form
            ref="form"
            @submit.prevent="updatePropertiesSitemapAdd"
            class="mt-10 mb-4"
          >
            <v-select
              :items="['dreamweeks', 'tripbeat']"
              dense
              outlined
              v-model.trim="updateSource"
              :loading="loading"
              :disabled="loading"
              label="Inventory Supplier"
              :rules="[rules.required]"
            ></v-select>
            <v-text-field
              label="3 or 4 digit property ID"
              outlined
              :rules="[rules.required]"
              dense
              hint="Copy &amp; paste the property ID"
              v-model.trim="idToAdd"
              :loading="loading"
              :disabled="loading"
              @keyup.enter="updatePropertiesSitemapAdd"
            ></v-text-field>
          </v-form>
          <v-fade-transition>
            <v-alert
              dense
              outlined
              type="error"
              v-if="error"
              icon="mdi-alert-outline"
            >
              {{ error }}
            </v-alert>
          </v-fade-transition>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="error"
            text
            @click="
              addPropertyDialog = false;
              error = '';
              $refs.form.reset();
            "
            :loading="loading"
            :disabled="loading"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            depressed
            @click="updatePropertiesSitemapAdd"
            :loading="loading"
            :disabled="loading"
          >
            Add Property
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add property dialog -->
    <!-- dev dialog -->
    <v-dialog v-model="devDialog" width="600" :loading="loading">
      <v-card>
        <v-card-text class="body-1 text--primary py-8">
          <ol style="font-family: 'Roboto Mono', monospace">
            <li>
              @mounted() gets properties sitemaps (ID 495142 &amp; 495893)
            </li>
            <li>pushes items to table (ID &amp; source)</li>
            <li>@onSubmit() updates &amp; refreshes sitemaps</li>
          </ol>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- dev dialog -->
    <v-footer absolute>
      <v-btn
        icon
        class="ml-auto"
        @click="devDialog = true"
        :loading="loading"
        :disabled="loading"
      >
        <v-icon>mdi-code-tags</v-icon>
      </v-btn>
    </v-footer>
  </v-container>
</template>

<script>
import axios from "axios";
import { portalsApp } from "@/firebase";

export default {
  mounted() {
    this.getSitemaps();
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    numberOfDWProperties() {
      return this.propertyIDs.filter((i) => i.source == "dreamweeks").length;
    },
    numberOfTBProperties() {
      return this.propertyIDs.filter((i) => i.source == "tripbeat").length;
    },
  },
  data: () => ({
    updateSource: "",
    propertyIDs: [],
    searchProperties: "",
    idToAdd: "",
    idToDelete: "",
    addPropertyDialog: false,
    deletePropertyDialog: false,
    devDialog: false,
    error: "",
    rules: {
      required: (v) => !!v || "Field Required",
    },
  }),
  methods: {
    async updatePropertiesSitemapDelete() {
      await this.$store.dispatch("setLoading", true);
      // format propertyID to delete

      let idToDelete = this.idToDelete.resortPropertyID;
      idToDelete = idToDelete.toUpperCase();

      // if dreamweeks
      if (this.idToDelete.source == "dreamweeks") {
        // archive inventoryProperty in collection
        let docRef = portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(idToDelete);
        if (docRef.exists)
          await docRef.update({
            archived: true,
          });
        // update sitemap
        let update = await axios.post(
          "/api/update-dw-properties-sitemap/delete",
          {
            idToDelete: idToDelete,
          }
        );

        if ((update.data.message = "success")) {
          await this.getSitemaps();
          this.deletePropertyDialog = false;
          await this.$store.dispatch("setLoading", false);
          await this.$store.dispatch("setSnackbar", {
            display: true,
            color: "success",
            text: "Property deleted successfully",
            timeout: 5000,
          });
          this.searchProperties = "";
        }
      }
      // if dreamweeks

      // if tripbeat
      if (this.idToDelete.source == "tripbeat") {
        // archive inventoryProperty in collection
        let docRef = portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(idToDelete);
        if (docRef.exists)
          await docRef.update({
            archived: true,
          });

        // update sitemap
        let update = await axios.post(
          "/api/update-tb-properties-sitemap/delete",
          {
            idToDelete: idToDelete,
          }
        );

        if ((update.data.message = "success")) {
          await this.getSitemaps();
          this.deletePropertyDialog = false;
          await this.$store.dispatch("setLoading", false);
          await this.$store.dispatch("setSnackbar", {
            display: true,
            color: "success",
            text: "Property deleted successfully",
            timeout: 5000,
          });
          this.searchProperties = "";
        }
      }
      // if tripbeat
    },

    async updatePropertiesSitemapAdd() {
      // check/error if form not valid
      if (!this.$refs.form.validate())
        return (this.error = "Please check form form errors");

      // format propertyID to add
      this.idToAdd = this.idToAdd.toUpperCase();

      // check/error if propertyID already in sitemap
      if (
        this.propertyIDs.filter((i) => i.resortPropertyID == this.idToAdd)
          .length
      )
        return (this.error = "This property ID is already in the database");

      // all checks met
      this.error = false;
      await this.$store.dispatch("setLoading", true);

      // if dreamweeks
      if (this.updateSource == "dreamweeks") {
        // check for existing property docRef
        let propertyDocRef = portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(this.idToAdd);
        let propertyDoc = await propertyDocRef.get();
        // if property doc doesn't exist => creating doc with properties
        if (!propertyDoc.exists) {
          let firestorePropertyData = {
            archived: false,
            commissionsPaidPercentage: 12,
            costOfExtras: 0,
            dateSubmitted: new Date().toISOString().substring(0, 10),
            dateUpdated: "",
            hasHighOccupancySurcharge: true,
            hasPeakSeasonSurcharge1: true,
            hasPeakSeasonSurcharge2: true,
            highOccupancySurchargeAmount: 459,
            minimumProfitAccepted: 300,
            peakSeasonSurcharge1Range: ["2021-05-31", "2021-08-31"],
            peakSeasonSurcharge2Range: ["2021-12-01", "2022-04-20"],
            peakSeasonSurchargeAmount: 469,
            source: "dreamweeks",
            taxesFeesPercentage: 0,
          };
          await propertyDocRef.set(firestorePropertyData, { merge: true });
        }

        // check for existing listings docRef
        let listingDocRef = portalsApp
          .firestore()
          .collection("inventoryListings")
          .doc(this.idToAdd);
        let listingDoc = await listingDocRef.get();
        // if listing doc doesn't exist => creating doc with properties
        if (!listingDoc.exists) {
          let firestoreListingData = {
            dateSubmitted: new Date().toISOString().substring(0, 10),
            destination: "",
            items: [],
            resortName: "",
            source: "dreamweeks",
          };
          await listingDocRef.set(firestoreListingData, { merge: true });
        }
        // construct URL to add to sitemap
        let urlToAdd = `https://www.dreamvacationweek.com/web/cs?a=1503&resortCode=${this.idToAdd}`;
        // update sitemap
        let update = await axios.post("/api/update-dw-properties-sitemap/add", {
          urlToAdd: urlToAdd,
        });
        if ((update.data.message = "success")) {
          await this.getSitemaps();
          this.addPropertyDialog = false;
          await this.$store.dispatch("setSnackbar", {
            display: true,
            color: "success",
            text: "Property added successfully",
            timeout: 5000,
          });
        }
        this.$router.push(`/inventory/${this.idToAdd}`);
      }
      // if dreamweeks

      // if tripbeat
      if (this.updateSource == "tripbeat") {
        // check for existing property docRef
        let propertyDocRef = portalsApp
          .firestore()
          .collection("inventoryProperties")
          .doc(this.idToAdd);
        let propertyDoc = await propertyDocRef.get();
        // if property doc doesn't exist => creating doc with properties
        if (!propertyDoc.exists) {
          let firestorePropertyData = {
            archived: false,
            commissionsPaidPercentage: 12,
            costOfExtras: 0,
            dateSubmitted: new Date().toISOString().substring(0, 10),
            dateUpdated: "",
            hasHighOccupancySurcharge: true,
            hasPeakSeasonSurcharge1: true,
            hasPeakSeasonSurcharge2: true,
            highOccupancySurchargeAmount: 459,
            minimumProfitAccepted: 300,
            peakSeasonSurcharge1Range: ["2021-05-31", "2021-08-31"],
            peakSeasonSurcharge2Range: ["2021-12-01", "2022-04-20"],
            peakSeasonSurchargeAmount: 469,
            source: "dreamweeks",
            taxesFeesPercentage: 0,
          };
          await propertyDocRef.set(firestorePropertyData, { merge: true });
        }

        // check for existing listings docRef
        let listingDocRef = portalsApp
          .firestore()
          .collection("inventoryListings")
          .doc(this.idToAdd);
        let listingDoc = await listingDocRef.get();
        // if listing doc doesn't exist => creating doc with properties
        if (!listingDoc.exists) {
          let firestoreListingData = {
            dateSubmitted: new Date().toISOString().substring(0, 10),
            destination: "",
            items: [],
            resortName: "",
            source: "dreamweeks",
          };
          await listingDocRef.set(firestoreListingData, { merge: true });
        }
        // construct URL to add to sitemap
        let urlToAdd = `https://www.tripbeat.com/search-resorts/resort/${this.idToAdd}`;
        // update sitemap
        let update = await axios.post("/api/update-tb-properties-sitemap/add", {
          urlToAdd: urlToAdd,
        });
        if ((update.data.message = "success")) {
          await this.getSitemaps();
          this.addPropertyDialog = false;
          await this.$store.dispatch("setSnackbar", {
            display: true,
            color: "success",
            text: "Property added successfully",
            timeout: 5000,
          });
          this.$router.push(`/inventory/${this.idToAdd}`);
        }
      }
      // if tripbeat
    },
    async getSitemaps() {
      await this.$store.dispatch("setLoading", true);
      this.propertyIDs = [];
      let array = [];

      // dreamweeks
      let dwRequest = await axios.get("/api/get-dw-properties-sitemap/");
      let dwData = JSON.parse(dwRequest.data);
      let dwStartUrl = dwData.startUrl;
      dwStartUrl.forEach((i) => {
        let item = {
          resortPropertyID: i.split("resortCode=")[1].trim(),
          source: "dreamweeks",
        };
        array.push(item);
      });
      // dreamweeks

      // tripbeat
      let tbRequest = await axios.get("/api/get-tb-properties-sitemap/");
      let tbData = JSON.parse(tbRequest.data);
      let tbStartUrl = tbData.startUrl;
      tbStartUrl.forEach((i) => {
        let item = {
          resortPropertyID: i.split("/resort/")[1].trim(),
          source: "tripbeat",
        };
        array.push(item);
      });
      // tripbeat

      this.propertyIDs = array;
      await this.$store.dispatch("setLoading", false);
    },
  },
};
</script>